const bodyEle = document.body

class SidePanel {
  constructor (element, overlay, toggleClass = 'side-panel-toggled') {
    this.element = element
    this.overlay = overlay
    this.toggleClass = toggleClass
    this.open = false
    this.togglers = []

    const links = element.getElementsByTagName('a')
    for (let i = 0, len = links.length; i < len; i++) {
      links[i].addEventListener('click', this.checkMenuLink)
    }
  }

  checkMenuLink = (event) => {
    const a = event.currentTarget
    const location = window.location
    const isEmptyHash = a.href.slice(-1) === '#'
    if (
      (isEmptyHash || a.hash.length) &&
      location.hostname === a.hostname &&
      location.pathname.replace(/^\//, '') === a.pathname.replace(/^\//, '')
    ) {
      if (isEmptyHash) {
        event.preventDefault()
      }
      this.toggle()
    }
  }

  toggle = () => {
    if (this.open) {
      bodyEle.classList.remove(this.toggleClass)
      if (this.overlay) {
        this.overlay.removeEventListener('click', this.toggle)
      }
    } else {
      bodyEle.classList.add(this.toggleClass)
      if (this.overlay) {
        this.overlay.addEventListener('click', this.toggle)
      }
    }
    this.open = !this.open
    const expanded = this.open ? 'true' : 'false'
    this.element.setAttribute('aria-expanded', expanded)
    for (let i = 0; i < this.togglers.length; i++) {
      this.togglers[i].setAttribute('aria-expanded', expanded)
    }
  }

  addToggler = (element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.toggle()
    })
    element.setAttribute('aria-expanded', this.open ? 'true' : 'false')
    this.togglers.push(element)
  }
}

const sidePanel = new SidePanel(document.getElementById('side-panel'), document.getElementById('page'))
sidePanel.addToggler(document.getElementById('header-side-panel-toggle'))

var overlaySidePanelToggle = document.getElementById('overlay-side-panel-toggle')
if (overlaySidePanelToggle) {
  sidePanel.addToggler(overlaySidePanelToggle)

  const scrollDistance = 64
  let lastKnownScrollPosition = 0
  let ticking = false

  function updateToggleVisibility(scrollPosition) {
    const progress = Math.max(0, Math.min(scrollPosition, scrollDistance)) / scrollDistance
    overlaySidePanelToggle.style.opacity = progress
    if (progress === 0) {
      overlaySidePanelToggle.style.visibility = 'hidden'
    } else {
      overlaySidePanelToggle.style.visibility = 'visible'
    }
  }

  document.addEventListener('scroll', function(e) {
    lastKnownScrollPosition = window.scrollY
    if (!ticking) {
      window.requestAnimationFrame(function() {
        updateToggleVisibility(lastKnownScrollPosition)
        ticking = false
      })
      ticking = true
    }
  })
}
