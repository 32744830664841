/*
Port of CMT Form 3.0.1 for standalone use.
Changes how strings are provided
*/

const NAME_PREFIX = '_cmt-form-'

if (!XMLHttpRequest.prototype.sendAsBinary) {
  XMLHttpRequest.prototype.sendAsBinary = function (sData) {
    var nBytes = sData.length, ui8Data = new Uint8Array(nBytes)
    for (var nIdx = 0; nIdx < nBytes; nIdx++) {
      ui8Data[nIdx] = sData.charCodeAt(nIdx) & 0xff
    }
    this.send(ui8Data)
  }
}

class CMTForm {
  constructor (form) {
    if (form.dataset.formInitialized) {
      console.warn('CMTForm: Same form was initialized twice.')
    } else {
      form.dataset.formInitialized = true
      this.element = form
      this.responseElement = form.querySelector('.cmt-form-response')
      this.changedElement = form.querySelector('.cmt-form-changed')

      this.inputs = form.elements
      this.isSubmitting = false
      this.changedWhileSubmitting = false
      this.unsavedChanges = false
      this.strings = {
        sending: form.dataset.stringSending,
        sent: form.dataset.stringSent,
        error: form.dataset.stringError
      }
      this._disabled = false
      this._submitButtonUsed = false
      this._loadTimestamp = Math.floor(Date.now() / 1000)
      form.addEventListener('submit', (event) => {
        event.preventDefault()
        const submitButtonUsed = this._submitButtonUsed
        this._submitButtonUsed = false // Clear it for future submissions, like using keyboard
        if (this._disabled) {
          return false
        }
        this.handleSubmit(submitButtonUsed)
      })
      for (const input of [...this.inputs]) {
        // Show submit buttons. They start hidden when the form requires JavaScript.
        if (input.type === 'submit') {
          input.hidden = false
        }
        // Initialize input
        this.initializeInput(input)
      }
      const event = new window.CustomEvent('cmt-form-initialized', {
        bubbles: true,
        cancelable: false,
        detail: {
          form: this,
          form_id: this.inputs['cmt-form'].value,
          form_uid: this.inputs['cmt-form-uid'].value
        }
      })
      window.dispatchEvent(event)
    }
  }

  initializeInput (input) {
    const type = input.type ? input.type : 'text'
    if (type === 'submit') {
      input.addEventListener('click', () => {
        this._submitButtonUsed = input
      })
    } else {
      input.addEventListener('input', () => {
        this.unsavedChanges = true
        if (this.isSubmitting) {
          this.changedWhileSubmitting = true
        }
        if (this.strings.changed) {
          this.changedElement.innerHTML = this.strings.changed
        }
      })
    }
  }

  disableSubmit () {
    this._disabled = true
    for (const input of [...this.inputs]) {
      if (input.type && input.type === 'submit') {
        input.disabled = true
      }
    }
  }

  enableSubmit () {
    this._disabled = false
    for (const input of [...this.inputs]) {
      if (input.type && input.type === 'submit') {
        input.disabled = false
      }
    }
  }

  getInputByName (name) {
    return this.inputs[NAME_PREFIX + name]
  }

  setResponse (message, success) {
    this.responseElement.innerHTML = message
    if (success) {
      this.responseElement.classList.add('cmt-form-success')
    } else {
      this.responseElement.classList.add('cmt-form-failure')
    }
    if (this.strings.changed) {
      this.changedElement.innerHTML = this.unsavedChanges ? this.strings.changed : ''
    }
  }

  setSending (submitButton) {
    this.responseElement.classList.remove('cmt-form-success')
    this.responseElement.classList.remove('cmt-form-failure')
    // Use the submit buttons own Sending string if preset, otherwise use the default
    this.responseElement.innerHTML = submitButton && submitButton.dataset.stringSending ? submitButton.dataset.stringSending : this.strings.sending
  }

  handleResponse (status, response) {
    this.isSubmitting = false
    if (status === 200) {
      var resp = JSON.parse(response)
      if (resp.success === true) {
        if (!this.changedWhileSubmitting && !resp.unchanged) {
          // Nothing changed during submission, and the response is not telling us that it didn't change
          this.unsavedChanges = false
        }
        this.setResponse('message' in resp ? resp.message : this.strings.sent, true)
        // Create the event.
        let event = new window.CustomEvent('cmt-form-sent', {
          bubbles: true,
          cancelable: false,
          detail: {
            form: this,
            form_id: this.inputs['cmt-form'].value,
            form_uid: this.inputs['cmt-form-uid'].value,
            response: resp
          }
        })
        window.dispatchEvent(event)
        if (resp.allow_resubmit) {
          this.enableSubmit()
        }
      } else {
        let err
        if (!resp.errors || !resp.errors.length) {
          err = this.strings.error
        } else {
          err = resp.errors.join('<br>')
        }
        this.setResponse(err, false)
        this.enableSubmit()
      }
    } else {
      this.setResponse(this.strings.error, false)
      this.enableSubmit()
    }
  }

  handleSubmit (submitButton) {
    this.disableSubmit()
    this.setSending(submitButton)
    this.isSubmitting = true
    this.changedWhileSubmitting = false

    this._status = 0
    this._segments = []
    for (var nItem = 0; nItem < this.inputs.length; nItem++) {
      const oField = this.inputs[nItem]
      if (!oField.hasAttribute('name')) {
        continue
      }
      const sFieldType = ['INPUT', 'BUTTON'].indexOf(oField.nodeName.toUpperCase()) !== -1 ? oField.getAttribute('type').toUpperCase() : 'TEXT'
      if (sFieldType !== 'SUBMIT' && oField.hasAttribute('disabled')) {
        // We skip disabled inputs, just like a regular nojs submission would
        // Note that submit buttons are handled separately below, since
        // they are disabled at the start of submission
        continue
      }
      if (sFieldType === 'FILE' && oField.files.length > 0) {
        for (let nFile = 0; nFile < oField.files.length; nFile++) {
          const oFile = oField.files[nFile]
          const oSegmReq = new FileReader()
          const segmentIdx = this._segments.length
          oSegmReq.onload = (oFREvt) => {
            this._segments[segmentIdx] += oFREvt.target.result + '\r\n'
            this._status--
            this.processStatus()
          }
          this._segments.push('Content-Disposition: form-data; name="' +
              oField.name + '"; filename="' + oFile.name +
              '"\r\nContent-Type: ' + oFile.type + '\r\n\r\n')
          this._status++
          oSegmReq.readAsBinaryString(oFile)
        }
      } else if ((sFieldType !== 'RADIO' && sFieldType !== 'CHECKBOX') || oField.checked) {
        if (sFieldType === 'SUBMIT') {
          if (submitButton !== oField) {
            continue // This is a submit button, but not the one used for submission, so we skip it!
          }
        }
        this._segments.push(
          'Content-Disposition: form-data; name="' + oField.name + '"\r\n\r\n' + oField.value + '\r\n'
        )
      }
    }
    this._segments.push(
      'Content-Disposition: form-data; name="cmt-form-load-timestamp"\r\n\r\n' + this._loadTimestamp + '\r\n',
      'Content-Disposition: form-data; name="cmt-form-send-timestamp"\r\n\r\n' + Math.floor(Date.now() / 1000) + '\r\n'
    )
    this.processStatus()
  }

  processStatus () {
    if (this._status > 0) {
      return
    }

    // We're done processing, submit!

    var xmlhttp = new XMLHttpRequest()
    xmlhttp.onload = () => {
      this.handleResponse(xmlhttp.status, xmlhttp.response)
    }

    xmlhttp.open('post', window.CMTConfig.rest + 'form/send', true)

    var sBoundary = '---------------------------' + Date.now().toString(16)
    xmlhttp.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + sBoundary)

    var sData = '--' + sBoundary + '\r\n' +
      this._segments.join('--' + sBoundary + '\r\n') + '--' + sBoundary + '--\r\n'

    var nBytes = sData.length
    var ui8Data = new Uint8Array(nBytes)
    for (let nIdx = 0; nIdx < nBytes; nIdx++) {
      ui8Data[nIdx] = sData.charCodeAt(nIdx) & 0xff
    }
    xmlhttp.send(ui8Data)
  }
}

export default CMTForm
